import { GroupBase } from 'react-select';
import { CustomSourceKeyAssociciatedClients, PickListInfo } from '../../../models/Picklist';
import { groupBy } from '../../../utils/ListUtils';
import { Item } from '../../shared/form-control/DropdownDefaultComponents';
import { Namespace, TFunction } from 'i18next';
import LanguageUtils from '../../../utils/LanguageUtils';

export const createPickListSource = (data: PickListInfo[], t: TFunction<Namespace>): GroupBase<Item>[] => {
  const grouped = groupBy([...data, { id: CustomSourceKeyAssociciatedClients, name: 'Associated Clients', isSystem: false }], 'isSystem');
  const picklist: GroupBase<Item>[] = [];

  for (const [key, value] of grouped) {
    picklist.push({
      label: key ? t('organisation:picklists.type.static') : t('organisation:picklists.type.dynamic'),
      options: value
        .map((picklist) => ({
          id: picklist.id,
          value: 'clientId' in picklist ? picklist.clientId || '' : '',
          text: `${'name' in picklist ? picklist.name : LanguageUtils.getTranslation('name', picklist.translations)} ${
            (picklist as PickListInfo).archivedUtc ? t('form-builder:action-properties.subform.template-archived') : ''
          }`,
          disabled: !!(picklist as PickListInfo).archivedUtc,
        }))
        .sort((a, b) => a.text.localeCompare(b.text)),
    });
  }
  return picklist;
};
